import { Provider } from "react-redux";
import RoutesConfig from "./routes/Routes";

import store from "./redux/store";
import { Suspense } from "react";

const App = () => {
  return (
    <Provider store={store}>
      <Suspense
        fallback={
          <div className="flex items-center justify-center h-screen bg-white">
            <div className="w-10 h-10 border-4 border-gray-300 border-t-blue-500 rounded-full animate-spin"></div>
          </div>
        }
      >
        <RoutesConfig />
      </Suspense>
    </Provider>
  );
};

export default App;
